import React, { forwardRef, ReactNode } from 'react';

import { CSS, styled } from '@/stitches.config';

const SectionStyled = styled('section', {
  position: 'relative',
  width: '100%',
  '&.sec-gallery': {
    '& + .sec-testimonial': {
      paddingTop: '$space-0',
    },
  },
  variants: {
    size: {
      '3xs': {
        paddingTop: '$space-4',
        paddingBottom: '$space-4',
      },
      '2xs': {
        paddingTop: '$space-6',
        paddingBottom: '$space-6',
      },
      xs: {
        paddingTop: '$space-8',
        paddingBottom: '$space-8',
      },
      sm: {
        paddingTop: '$space-10',
        paddingBottom: '$space-10',
      },
      md: {
        paddingTop: '$space-10',
        paddingBottom: '$space-10',
        '@sm': {
          paddingTop: '$space-12',
          paddingBottom: '$space-12',
        },
      },
      lg: {
        paddingTop: '$space-10',
        paddingBottom: '$space-10',
        '@sm': {
          paddingTop: '$space-12',
          paddingBottom: '$space-12',
        },
        '@md': {
          paddingTop: '$space-14',
          paddingBottom: '$space-14',
        },
      },
      xl: {
        paddingTop: '$space-10',
        paddingBottom: '$space-10',
        '@sm': {
          paddingTop: '$space-12',
          paddingBottom: '$space-12',
        },
        '@md': {
          paddingTop: '$space-14',
          paddingBottom: '$space-14',
        },
        '@lg': {
          paddingTop: '$space-16',
          paddingBottom: '$space-16',
        },
      },
      '2xl': {
        paddingTop: '$space-10',
        paddingBottom: '$space-10',
        '@sm': {
          paddingTop: '$space-14',
          paddingBottom: '$space-14',
        },
        '@md': {
          paddingTop: '$space-16',
          paddingBottom: '$space-16',
        },
        '@lg': {
          paddingTop: '$space-20',
          paddingBottom: '$space-20',
        },
      },
    },
    backgroundColor: {
      red: {
        bc: '$primary',
      },
      'red-dark': {
        bc: '$primary100',
      },
      blue: {
        bc: '$blue',
      },
      white: {
        bc: '$white',
      },
      'gray-light': {
        bc: '$gray200',
      },
      gray: {
        bc: '$gray400',
      },
      'gray-dark': {
        bc: '$gray900',
      },
      black: {
        bc: '$black',
      },
      'gradient-gray-light': {
        bc: '$gray200',
        linearGradient: 'to top, $gray200 0%, $white 100%',
      },
      'gradient-gray-light-invert': {
        bc: '$gray200',
        linearGradient: 'to bottom, $gray200 0%, $white 100%',
      },
      'gradient-gray': {
        bc: '$gray700',
        linearGradient: '0deg, $gray700 0%, $gray600 100%',
      },
      'gradient-gray-dark': {
        bc: '$gray900',
        linearGradient: '0deg, $gray900 0%, $gray800 100%',
      },
      'gradient-black': {
        bc: '$gray900',
        linearGradient: '270deg, $gray900 0%, $black 100%',
      },
      'product-xake-safety': {
        background:
          'linear-gradient(180deg, rgba(0, 0, 0, 0) 66%, rgba(0, 0, 0, 0.7) 100%), linear-gradient(187.56deg, #000000 0%, #000000 44.66%, rgba(0, 0, 0, 0) 62.78%), linear-gradient(216.86deg, #7F1832 21.42%, #000000 70.94%, #7F1832 100%)',
        '@lg': {
          background:
            'linear-gradient(187.56deg, #000000 18.23%, rgba(0, 0, 0, 0.26) 61.98%, rgba(0, 0, 0, 0) 100%), linear-gradient(233.61deg, #7F1832 27.68%, #000000 41.09%, #000000 77.67%, #7F1832 99.5%)',
        },
      },
    },
    overflow: {
      hidden: {
        overflow: 'hidden',
      },
      visible: {
        overflow: 'visible',
      },
    },
  },
  defaultVariants: {
    overflow: 'hidden',
  },
});

/**
 * Interface that defines the props for the Section component.
 */
interface SectionProps extends React.ComponentProps<typeof SectionStyled> {
  /**
   * The size of the section.
   */
  size?: '2xs' | '3xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
  /**
   * The overflow behavior of the section.
   * @default hidden
   */
  overflow?: 'hidden' | 'visible';
  /**
   * The HTML element to use for the section.
   * @default section
   */
  as?: 'div' | 'article' | 'aside' | 'details' | 'main' | 'section' | 'footer';
  /**
   * Additional CSS class name for the section.
   */
  className?: string;
  /**
   * Additional CSS styling for the section.
   */
  css?: CSS;
  /**
   * Data-test attribute for the section.
   */
  test: string;
  /**
   * The content of the section.
   */
  children?: ReactNode;
}

/**
 * The Section component is a layout component that renders a section with additional styling and functionality.
 * @param props - The props for the Section component.
 * @param props.children - The content of the section.
 * @param props.size - The size of the section.
 * @param props.overflow - The overflow behavior of the section.
 * @param props.as - The HTML element to use for the section.
 * @param props.className - Additional CSS class name for the section.
 * @param props.css - Additional CSS styling for the section.
 * @param props.test - Data-test attribute for the section.
 * @param ref - The ref for the section element.
 * @returns The rendered Section component.
 */
export const Section = forwardRef<HTMLDivElement, SectionProps>(
  (
    {
      children,
      className,
      css,
      test,
      as = 'section',
      size,
      overflow = 'hidden',
      ...rest
    },
    ref
  ) => {
    return (
      <SectionStyled
        className={className}
        css={css}
        data-test={test}
        as={as}
        ref={ref}
        size={size}
        overflow={overflow}
        {...rest}
      >
        {children}
      </SectionStyled>
    );
  }
);

Section.displayName = 'Section';
