import React from 'react';

import { Button } from '@/components/shared/buttons/button';
import { styled } from '@/stitches.config';
import { IButton } from '@/types/shared';
import { ITest, ITracking } from '@/types/tracking';

const StyledButtonGroup = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  rowGap: '$space-3',
  columnGap: '$space-4',
  '@xs': {
    columnGap: '$space-6',
  },
  '@lg': {
    justifyContent: 'flex-start',
  },
  variants: {
    wrap: {
      noWrap: {
        flexWrap: 'nowrap',
      },
      wrap: {
        flexWrap: 'wrap',
      },
      wrapReverse: {
        flexWrap: 'wrap-reverse',
      },
    },
    align: {
      start: {
        alignItems: 'flex-start',
      },
      center: {
        alignItems: 'center',
      },
      end: {
        alignItems: 'flex-end',
      },
      baseline: {
        alignItems: 'baseline',
      },
    },
    justify: {
      start: {
        justifyContent: 'flex-start',
      },
      center: {
        justifyContent: 'center',
      },
      end: {
        justifyContent: 'flex-end',
      },
    },
  },
  defaultVariants: {
    wrap: 'wrap',
    align: 'center',
  },
});

interface ButtonGroupProps
  extends React.ComponentProps<typeof StyledButtonGroup> {
  children?: React.ReactNode;
  buttons?: IButton[];
  size?: 'sm' | 'md' | 'lg' | 'xl';
  variant?:
    | 'button-red'
    | 'button-black'
    | 'button-gray'
    | 'button-gray-light'
    | 'button-white'
    | 'button-white-text-blue'
    | 'button-white-text-green'
    | 'button-blue'
    | 'button-green';
  border?: boolean;
  isMinWidth?: boolean;
  isFull?: boolean;
  tracking?: ITracking;
  test?: ITest;
}

export const ButtonGroup: React.FC<ButtonGroupProps> = ({
  children,
  buttons = [],
  size,
  variant,
  border,
  isMinWidth = false,
  isFull,
  tracking,
  test,
  ...rest
}: ButtonGroupProps) => {
  return (
    <StyledButtonGroup {...rest}>
      {buttons.length > 0
        ? buttons.map((button, index) => (
            <Button
              key={index}
              size={size}
              label={button.label}
              href={button.url}
              target={button.target}
              variant={button.color || variant}
              icon={button.icon}
              iconPosition={button.iconPosition}
              border={button.type === 'border' || border}
              isFull={button.isFull || isFull}
              isMinWidth={button.isMinWidth || isMinWidth}
              tracking={{
                dataTrack: tracking?.dataTrack,
                dataTrackSection: tracking?.dataTrackSection,
                dataTrackValue: tracking?.dataTrackValue,
                dataTrackText: button.label,
                dataTrackUrl: button.url,
              }}
              test={{
                dataTest: `${test?.dataTest}_${index}`,
              }}
            />
          ))
        : children}
    </StyledButtonGroup>
  );
};

ButtonGroup.displayName = 'ButtonGroup';
